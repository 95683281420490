module.exports = {
    pcregister: {
        p1: 'มีบัญชีอยู่แล้ว?',
        dl: 'เข้าสู่ระบบ',
        zc: 'ลงทะเบียน',
        p4: 'บัญชีผู้ใช้',
        p5: 'กรุณากรอกบัญชี',
        p6: 'กรุณาใส่รหัสผ่าน',
        p7: 'กรุณากรอกรหัสผ่านยืนยัน',
        p8: 'กรุณากรอกรหัสเชิญ',
        p9: 'ฉันเห็นด้วย',
        p10: '《ข้อตกลงผู้ใช้และนโยบายความเป็นส่วนตัว》',
        p11: 'จดหมาย',
        p12: 'กรุณาใส่อีเมลของคุณ',
        p13: 'กรุณากรอกรหัสยืนยันอีเมล์',
        p14: 'ส่ง',
        p15: 'ปี',
        p16: 'เดือน',
        p17: 'ท้องฟ้า',
        p18: 'ชั่วโมง',
        p19: 'นาที',
        p20: 'ที่สอง'
    },
    pclogin: {
        l1: 'ไม่มีบัญชี?',
        l2: 'ลงทะเบียน',
        l3: 'เข้าสู่ระบบ',
        l4: 'กรุณาใส่ชื่อผู้ใช้',
        l5: 'กรุณาใส่รหัสผ่าน',
        l6: 'ลืมรหัสผ่าน',
        l7: 'เข้าสู่ระบบ'
    },
    pccontract: {
        ss: 'ราคาตลาด',
        xj: 'ขีด จำกัด ราคา',
        gg: 'คันโยก'
    },
    pcrapidly: {
        zf: 'เพิ่ม',
        dd: 'คำสั่ง',
        bdsj: 'เวลาท้องถิ่น',
        ky: 'มีอยู่'
    },
    pchome: {
        global1: 'แพลตฟอร์มการซื้อขายสินทรัพย์ดิจิทัลชั้นนำของโลก',
        global2: 'ให้บริการธุรกรรมสินทรัพย์ดิจิทัลที่ปลอดภัยและเชื่อถือได้และบริการจัดการสินทรัพย์แก่ผู้ใช้หลายล้านคนในกว่า 100 ประเทศ',
        global3: 'ปลอดภัยและเชื่อถือได้',
        global4: 'ประสบการณ์ห้าปีในบริการทางการเงินสินทรัพย์ดิจิทัล มุ่งเน้นไปที่สถาปัตยกรรมแบบกระจายและระบบต่อต้านการโจมตี DDoS',
        global5: 'รูปแบบทางนิเวศวิทยาทั่วโลก',
        global6: 'มีการจัดตั้งศูนย์บริการธุรกรรมที่แปลเป็นภาษาท้องถิ่นในหลายประเทศเพื่อสร้างระบบนิเวศบล็อกเชนที่รวมรูปแบบธุรกิจต่างๆ',
        global7: 'ผู้ใช้ก่อน',
        global8: 'สร้างกลไกการชดเชยล่วงหน้าและจัดตั้งกองทุนคุ้มครองผู้ลงทุน',
        whever1: 'ธุรกรรมเทอร์มินัลหลายแพลตฟอร์มทุกที่ทุกเวลา',
        whever2: 'ครอบคลุม iOS, Android, Windows หลายแพลตฟอร์ม รองรับการทำงานทางธุรกิจเต็มรูปแบบ',
        register1: 'ลงทะเบียน',
        register3: 'บัญชีสากลเริ่มทำการซื้อขาย',
        register2: 'สมัครเลย'
    },
    pcbottom: {
        box1: 'ชั้นนำของโลก',
        box2: 'หนึ่งในทีมที่ดีที่สุด',
        box3: 'มากกว่า',
        box4: 'เกี่ยวกับเรา',
        box5: 'กระดาษสีขาว',
        box6: 'ให้บริการ',
        box7: 'ดาวน์โหลด',
        box8: 'ปัญหาที่พบบ่อย',
        box9: 'บริการออนไลน์',
        box10: 'ระเบียบข้อบังคับ',
        box11: 'ข้อตกลงการใช้',
        box12: 'นโยบายความเป็นส่วนตัว',
        box13: 'ประกาศทางกฎหมาย',
        box14: 'เชื่อมต่อ'
    },
    pcAbout_us: {
        t1: 'คอร์ส',
        t2: 'เรา 7 ปีของถนน',
        t3: 'นับตั้งแต่ก่อตั้งในปี 2558 เราได้อุทิศตนเพื่อให้บริการบล็อกเชนด้วยเทคโนโลยีเต็มเวลาและบริการสร้างช่องทาง',
        t4: 'จากปี 2014 ถึงปี 2017 ฉันพบกับช่วงขาขึ้นของ "การเติบโตที่มั่นคง" ครึ่งแรกของปี 2017-2018 เป็นช่วงเวลาที่บ้าคลั่งในการ "ซื้อเงินเพื่อรวย" ตั้งแต่ครึ่งหลังของปี 2019 จนถึงฤดูหนาวที่หนาวเย็นในวันนี้ ฉันมี พัฒนาทักษะที่แท้จริงมากมายนับไม่ถ้วนพันธมิตรที่ประสบความสำเร็จทำให้เราเป็นคนธรรมดา ๆ เรามีชื่อเสียงโด่งดังในอุตสาหกรรม ในปี 2565 เราจะมีแพลตฟอร์มใหม่ล่าสุด V3.0 และจัดตั้งบริษัทร่วมหุ้นใหม่ BitGoldEX เพื่อดำเนินการ ธุรกิจมากกว่า 100 ประเทศทั่วโลก',
        t5: 'เรากำลังมองหาใคร',
        t6: 'ตั้งแต่เริ่มต้นจนถึงวันนี้',
        t7: 'ฉันมองหาผู้ให้บริการที่ประสบปัญหาและมีรสนิยมเดียวกับฉันฉันสามารถแก้ไขปัญหาให้กับผู้ให้บริการหลายสิบรายได้สำเร็จภายในเวลาไม่กี่ปี',
        t8: 'ตามหลักเหตุผลในปัจจุบัน หากคุณต้องการหาผู้ช่วยที่ดี คู่คิดที่สามารถทำงานร่วมกันเพื่อตระหนักรู้ในตัวเองและก้าวต่อไป คุณอาจคุยกับเราได้',
        t9: 'โปรดเชื่อเถอะว่าคนที่คุณตามหากำลังตามหาคุณอยู่',
        t10: 'ให้บริการ',
        t11: 'ไม่มีพนักงานขาย แต่มีธุรกิจ',
        t12: 'ถ้าเพื่อนแนะนำธุรกิจให้คุณ แสดงว่าคุณเป็นคนดี เมื่อเพื่อนร่วมงานแนะนำธุรกิจให้คุณ แสดงว่าคุณมีชื่อเสียงที่ดี เมื่อลูกค้าแนะนำธุรกิจให้คุณ แสดงว่าคุณน่าเชื่อถือ',
        t13: 'วิญญาณ',
        t14: 'เราเป็นก้อนอิฐ ไม่ต้องไปหาที่ไหน',
        t15: 'ลูกค้าต่างมีความต้องการที่แตกต่างกัน ผลิตภัณฑ์ต่าง ๆ ลักษณะเฉพาะ โอกาสต่าง ๆ และเป้าหมายต่าง ๆ ความหมายของการดำรงอยู่ของเราคือการปรับให้เข้ากับแนวคิด ระบบ และข้อกำหนดของผู้ให้บริการต่าง ๆ และแก้ปัญหาต่าง ๆ',
        t16: 'เราเต็มใจที่จะเป็นก้อนอิฐที่มีความคิดและหน้าที่ จำเป็นแค่ไหน เราจะไปที่นั่น'
    },
    pcproblem: {
        p1: 'ปัญหาที่พบบ่อย',
        p2: 'เหตุใดจำนวนสินทรัพย์ที่แปลงจึงเปลี่ยนไป',
        p3: 'การคำนวณสินทรัพย์ที่เทียบเท่าคือมูลค่าของสกุลเงินดิจิทัลที่ถือครองอยู่ในปัจจุบันที่แปลงเป็น USDT ซึ่งเปลี่ยนแปลงเนื่องจากความผันผวนของราคาของสินทรัพย์ดิจิทัล ปริมาณสินทรัพย์ดิจิทัลของคุณไม่เปลี่ยนแปลง',
        p4: 'วิธีจัดการกับการลืมรหัสผ่านกองทุนในการทำธุรกรรมบนแพลตฟอร์ม',
        p5: 'หากคุณลืมรหัสผ่านกองทุนธุรกรรมแพลตฟอร์ม โปรดไปที่ "การตั้งค่าของฉัน-คลิกเพื่อตั้งรหัสผ่านกองทุน" หรือติดต่อฝ่ายบริการลูกค้าเพื่อรีเซ็ต',
        p6: 'เงินไปที่ไหนหลังจากซื้อธุรกรรม?',
        p7: 'หลังจากการสั่งซื้อเสร็จสมบูรณ์ เงินจะเข้าสู่บัญชีสกุลเงินตามกฎหมายในสินทรัพย์ หากคุณต้องการซื้อสินทรัพย์สำหรับธุรกรรมสัญญาหรือธุรกรรมฟิวเจอร์ส โปรดไปที่โมดูลการโอนเงินเพื่อโอน',
        p8: 'ทำไมต้องจำกัดจำนวนการเปลี่ยนชื่อเล่น',
        p9: 'เพื่อความปลอดภัยในการทำธุรกรรมของคุณ ขณะนี้เราจำกัดชื่อเล่นให้แก้ไขได้เพียงครั้งเดียวเท่านั้น',
        p10: 'หมายความว่าอย่างไรเมื่อคุณโอนและทำไมคุณต้องโอน',
        p11: 'เพื่อให้แน่ใจว่าเงินทุนระหว่างบัญชีของคุณเป็นอิสระและเพื่ออำนวยความสะดวกในการควบคุมความเสี่ยงที่สมเหตุสมผลของคุณ เราขอแบ่งการโอนบัญชีของแต่ละโมดูลการซื้อขายหลัก การโอนหมายถึงกระบวนการแปลงสินทรัพย์ระหว่างบัญชีซื้อขาย',
        p12: 'วิธีคำนวณส่วนของบัญชีของสัญญาผู้ใช้',
        p13: 'มูลค่าสุทธิของบัญชีตามสัญญา = ส่วนต่างของตำแหน่ง + กำไรและขาดทุนลอยตัวของตำแหน่ง + จำนวนเงินที่มีอยู่ของบัญชีปัจจุบัน',
        p14: 'มูลค่าของการตั้งค่า Stop Loss และ Take Profit เมื่อเปิดสถานะคือเท่าใด และควรตั้งค่าอย่างไร',
        p15: 'ขีดจำกัดบนของกำไรและขาดทุนที่ตั้งไว้สำหรับคุณขึ้นอยู่กับกำไรและขาดทุน เมื่อถึงจำนวน stop profit และ stop loss ที่ตั้งไว้ ระบบจะปิดสถานะโดยอัตโนมัติ สามารถใช้สำหรับการควบคุมความเสี่ยงเมื่อซื้อสัญญาที่ตั้งไว้ครึ่งหนึ่ง ของจำนวนหยุดกำไรเป็น: เพิ่ม x ปริมาณ x เลเวอเรจทวีคูณ การตั้งค่าหยุดการขาดทุน เราขอแนะนำให้คุณตั้งค่าตามสถานการณ์สินทรัพย์จริงของคุณเพื่อควบคุมความเสี่ยงอย่างสมเหตุสมผล',
        p16: 'วิธีลดความเสี่ยงสัญญา',
        p17: 'คุณสามารถโอนสินทรัพย์ที่มีอยู่ของบัญชีที่เหลือไปยังบัญชีสัญญาได้โดยการโอนเงิน หรือลดความเสี่ยงโดยการลดตำแหน่ง',
        p18: 'ปริมาณการเปิดสัญญาหมายถึงอะไร?',
        p19: 'จำนวนตำแหน่งที่เปิดแสดงถึงจำนวนสกุลเงินที่คุณคาดว่าจะซื้อ ตัวอย่างเช่น ในหน้าเริ่มต้นของคู่การซื้อขาย BTC/USDT ให้เลือก เปิดสถานะ Long ป้อนราคาเป็น 1,000USDT และป้อนปริมาณเป็น 10BTC นั่นคือ : คุณคาดว่าจะซื้อ 10 BTC ที่ราคาต่อหน่วย 1,000USDT',
        p20: 'ค่าธรรมเนียมการจัดการในสัญญาคำนวณอย่างไร?',
        p21: 'ค่าธรรมเนียมการจัดการ = ราคาเปิด * ปริมาณการเปิด * อัตราค่าธรรมเนียมการจัดการ',
        p22: 'ข้อควรระวังในการบังคับชำระบัญชี',
        p23: 'ระดับความเสี่ยงเป็นตัวบ่งชี้เพื่อวัดความเสี่ยงของสินทรัพย์ของคุณ เมื่อระดับความเสี่ยงเท่ากับ 100% สถานะของคุณจะถูกพิจารณาว่าปิดแล้ว ระดับความเสี่ยง = (ส่วนต่างการถือครอง / ส่วนบัญชีสัญญา) * 100% ปรับอัตราส่วนความเสี่ยง เมื่อระดับความเสี่ยงถึงค่าความเสี่ยงที่ระบบกำหนดระบบจะบังคับชำระบัญชี ตัวอย่างเช่น อัตราส่วนการปรับปรุงที่ระบบกำหนดไว้คือ 10% จากนั้นเมื่อระดับความเสี่ยงของคุณมากกว่าหรือเท่ากับ 90% สถานะทั้งหมดของคุณ จะถูกปิดระบบบังคับชำระบัญชี',
        p24: 'กฎการซื้อขายสัญญาคืออะไร',
        p25: 'ประเภทธุรกรรม',
        p26: 'ประเภทธุรกรรมแบ่งออกเป็นทิศทางยาว (ซื้อ) และสั้น (ขาย):',
        p27: 'การซื้อระยะยาว (ขาขึ้น) หมายความว่าคุณเชื่อว่าดัชนีปัจจุบันมีแนวโน้มสูงขึ้น และคุณต้องการซื้อจำนวนหนึ่งในจำนวนที่กำหนดเพื่อทำสัญญาในราคาที่คุณกำหนดหรือราคาตลาดปัจจุบัน',
        p28: 'การขายชอร์ต (ตลาดหมี) หมายความว่าคุณเชื่อว่าดัชนีปัจจุบันอาจตกลงและต้องการขายสัญญาใหม่จำนวนหนึ่งในราคาที่คุณกำหนดหรือราคาตลาดปัจจุบัน',
        p29: 'วิธีการสั่งซื้อ',
        p30: 'คำสั่งจำกัด: คุณต้องระบุราคาและปริมาณของคำสั่ง',
        p31: 'คำสั่งซื้อขายในตลาด: คุณจะต้องกำหนดปริมาณการสั่งซื้อเท่านั้น และราคาคือราคาตลาดปัจจุบัน',
        p32: 'ตำแหน่ง',
        p33: 'เมื่อคำสั่งที่คุณส่งเพื่อเปิดตำแหน่งเสร็จสมบูรณ์ จะเรียกว่าตำแหน่ง',
        p34: 'ปัญหาการส่งมอบสัญญา',
        p35: 'สัญญาแพลตฟอร์มเป็นสัญญาถาวรและไม่มีเวลาส่งมอบ ตราบเท่าที่ระบบไม่ตรงตามเงื่อนไขบังคับชำระบัญชีหรือคุณไม่ได้ปิดตำแหน่งด้วยตนเอง คุณสามารถดำรงตำแหน่งได้อย่างถาวร ระบบจะปิดตำแหน่ง',
        p36: '1: ระบบจะปิดสถานะโดยอัตโนมัติเมื่อถึงค่าที่ตั้งไว้ของ stop profit และ stop loss',
        p37: '2: ความเสี่ยงสูงเกินไป ระบบบังคับให้ชำระบัญชี',
        p38: 'Margin สำหรับการซื้อขายสัญญาเป็นเท่าใด?',
        p39: 'ในการซื้อขายสัญญา ผู้ใช้สามารถเข้าร่วมในการซื้อขายสัญญาตามราคาและปริมาณของสัญญา รวมถึงเลเวอเรจทวีคูณที่สอดคล้องกัน เมื่อผู้ใช้เปิดตำแหน่ง พวกเขาจะมีมาร์จิ้น ยิ่งมีมาร์จิ้นสำหรับการเปิดตำแหน่งมากเท่าไหร่ ความเสี่ยงของบัญชี',
        p40: 'คำสั่งจำกัดและคำสั่งตลาดคืออะไร?',
        p41: 'คำสั่งจำกัดหมายถึงราคาที่คุณคาดว่าจะมอบความไว้วางใจให้แพลตฟอร์มในการซื้อขายและคำสั่งซื้อขายในตลาดหมายถึงการมอบความไว้วางใจโดยตรงให้กับแพลตฟอร์มในการซื้อขายในราคาปัจจุบัน ในกฎการเปิด Position คำสั่งซื้อขายในตลาดจะมีความสำคัญเหนือคำสั่งจำกัด หาก คุณเลือกคำสั่งจำกัด โปรดติดตามสกุลเงิน ราคาตลาดปัจจุบันเหมาะสมในการเปิดสถานะเพื่อหลีกเลี่ยงการขาดทุนที่เกิดจากราคาเปิดที่ไม่สมเหตุสมผล',
        p42: 'ระดับความเสี่ยงของธุรกรรมสัญญาแสดงถึงอะไร?',
        p43: 'ระดับความเสี่ยงคือดัชนีความเสี่ยงในบัญชีสัญญาของคุณ ระดับความเสี่ยงเท่ากับ 100% ซึ่งถือว่าเป็นการชำระบัญชี เราแนะนำว่าเมื่อความเสี่ยงของคุณเกิน 50% คุณต้องเปิดตำแหน่งอย่างระมัดระวังเพื่อหลีกเลี่ยงการขาดทุนที่เกิดจากการปิดตำแหน่ง คุณสามารถเสริมสินทรัพย์สัญญา เงินที่มีอยู่ หรือแบ่งเบาตำแหน่งเพื่อลดความเสี่ยง',
        p44: 'ทำไมคุณต้องการแลกเปลี่ยนสกุลเงิน?',
        p45: 'จุดประสงค์ของการแลกเปลี่ยนคือเพื่อให้การไหลเวียนของเงินทุนในสกุลเงินต่าง ๆ ในสินทรัพย์ของคุณเป็นไปอย่างราบรื่น QCC ที่ได้รับในบัญชีฟิวเจอร์สสามารถแปลงเป็น USDT ได้อย่างอิสระสำหรับการซื้อขาย และ USDT ในบัญชีอื่น ๆ ยังสามารถแปลงเป็น QCC ได้อย่างอิสระสำหรับ ซื้อขาย.',
        p46: 'ทำไมอีกฝ่ายไม่ได้รับเงินโอนภายในเวลาที่โอน?',
        p47: 'ขั้นแรก โปรดตรวจสอบให้แน่ใจว่าวิธีการชำระเงินที่คุณกรอกเมื่อโอนเงินตรงกับวิธีการชำระเงินที่แสดงในหน้ารายละเอียดการสั่งซื้อ จากนั้น โปรดยืนยันว่าวิธีการชำระเงินของคุณเป็นการชำระเงินทันทีหรือการชำระเงินล่าช้า สุดท้าย โปรดติดต่อธนาคารของคุณ และสถาบันการชำระเงินสำหรับการบำรุงรักษาระบบหรือเหตุผลอื่นๆ',
        p48: 'จะซื้อ USDT ผ่านแพลตฟอร์มได้อย่างไร?',
        p49: 'วิธีที่ 1: เลือกคำสั่งซื้อที่คุณต้องการซื้อผ่านรายการซื้อและขายของแพลตฟอร์มเพื่อซื้อหรือขาย',
        p50: 'วิธีที่ 2: คลิกปุ่มเผยแพร่เพื่อเผยแพร่คำสั่งซื้อหรือขายตามความต้องการของคุณสำหรับการทำธุรกรรมที่รวดเร็ว ระบบจะจับคู่คุณกับผู้ซื้อและผู้ขายอย่างรวดเร็ว',
        p51: 'หมายเหตุ: หากคำสั่งซื้อไม่ได้รับการชำระเงินหลังจาก 15 นาที ระบบจะยกเลิกคำสั่งซื้อโดยอัตโนมัติ โปรดชำระเงินให้ทันเวลา หากคุณยกเลิกคำสั่งซื้อมากกว่า 3 ครั้งในวันเดียวกัน คุณจะไม่สามารถซื้อขายได้อีกในวันเดียวกัน และ สิทธิ์การซื้อขายจะได้รับการกู้คืนในวันถัดไป'
    },
    public: {
        zxkf: 'บริการลูกค้าออนไลน์',
        sure: 'ยืนยัน',
        cancel: 'ยกเลิก',
        RefreshSucceeded: 'รีเฟรชสำเร็จ',
        noMoreData: 'ไม่มีข้อมูลเพิ่มเติมให้อ่าน',
        pullingText: 'ดึงเพื่อรีเฟรช ...',
        ReleaseText: 'ปล่อยเพื่อรีเฟรช ...',
        loadingText: 'กำลังโหลด ...',
        noData: 'ไม่พบบันทึก',
        xq: 'รายละเอียด',
        submit: 'ส่ง',
        kefu: 'บริการลูกค้าออนไลน์'
    },

    ChangeType: {
        Upper: 'เงินฝากมาถึง',
        Lower: 'การถอนเงิน',
        Win: 'กำไร',
        Bet: 'เดิมพัน',
        BuyMining: 'เหมืองแร่',
        CoinWin: 'กำไรโทเค็นใหม่',
        SettleMining: 'คืนทุนในการขุด',
        GiveInterest: 'ดอกเบี้ยการขุด',
        BuyCoin: 'การซื้อโทเค็นใหม่',
        BuyCoinBack: 'คืนจากการซื้อโทเค็นใหม่',
        UsdtToBtc: 'Usdt เป็น Btc',
        UsdtToEth: 'Usdt เป็น Eth',
        BtcToUsdt: 'Btc เป็น Usdt',
        BtcToEth: 'Btc เป็น Eth',
        EthToUsdt: 'Eth ถึง Usdt',
        EthToBtc: 'Eth ถึง Btc',
        CoinThaw: 'การละลายของสกุลเงินใหม่',
        CoinFrozen: 'สกุลเงินใหม่แช่แข็ง',
        CoinCancel: 'คำสั่งสกุลเงินยกเลิก',
        CoinBuy: 'ซื้อสกุลเงิน',
        CoinBuyEntrust: 'สกุลเงิน ซื้อความไว้วางใจ',
        CoinSellEntrust: 'ความไว้วางใจในการขายสกุลเงิน',
        CoinSell: 'ขายสกุลเงิน',
        ContractBuy: 'คำสั่งซื้อตามสัญญาหักส่วนต่าง',
        ContractCharge: 'คำสั่งซื้อตามสัญญาหักค่าธรรมเนียมการจัดการ',
        ContractCancelToBond: 'คืนเงินมัดจำหลังยกเลิกสัญญา',
        ContractCancelToCharge: 'การขอคืนค่าบริการกรณียกเลิกสัญญา',
        ContractCloseProfit: 'รายได้ปิดสัญญา'
    },
    trade: {
        title: 'แลกเปลี่ยน',
        Buy: 'ซื้อ',
        Sell: 'ขาย',
        pc: 'ปิด',
        cb: 'ค่าใช้จ่าย',
        xj1: 'ราคาปัจจุบัน',
        yk: 'กำไรและขาดทุน',
        xianjia: 'ราคาคงที่',
        jiage: 'ราคา',
        shuliang: 'จำนวน',
        keyong: 'มีอยู่',
        yonghuchicang: 'ตำแหน่ง',
        fxlts: 'อัตราความเสี่ยง = ส่วนต่างของตำแหน่ง / ส่วนของลูกค้าคูณด้วย 100% โดยที่ส่วนของลูกค้าเท่ากับเงินทุนที่มีอยู่ในบัญชี + ส่วนต่างของธุรกรรมเริ่มต้น + กำไรและขาดทุนแบบลอยตัว',
        fxl: 'อัตราอันตราย',
        dqwt: 'เปิดออเดอร์',
        name: 'ชื่อ',
        leixing: 'พิมพ์',
        all: 'ทั้งหมด',
        shijian: 'เวลา',
        long: 'ยาว',
        short: 'สั้น',
        cc: 'ดำรงตำแหน่ง',
        zyjg: 'ราคาดีที่สุดในปัจจุบัน',
        caozuo: 'การดำเนินการ',
        xj: 'ราคาคงที่',
        sj: 'ราคาตลาด',
        jiaoyie: 'จำนวน',
        orderNo: 'เลขที่ใบสั่งซื้อ',
        weituojia: 'ราคา',
        chengjiaojia: 'ราคา',
        chengjiaoe: 'ราคาซื้อขาย',
        weituo: 'โหมด',
        qbwt: 'ผู้แทนทั้งหมด',
        lswt: 'ประวัติการสั่งซื้อ',
        tips: 'กรุณาใส่ราคา',
        tips1: 'กรุณาใส่ตัวเลข',
        Create: 'ความไว้วางใจ',
        Wait: 'ธุรกรรม',
        Complete: 'ปิด',
        Cancel: 'เพิกถอน',
        zsj: 'ราคาหยุดการขาดทุน',
        zyj: 'หยุดราคากำไร',
        cczsy: 'รายได้รวมจากตำแหน่ง',
        ykbl: 'อัตราส่วนกำไรขาดทุน',
        bili: 'อัตราส่วน',
        jyss: 'จำนวนการทำธุรกรรม',
        bzj: 'ระยะขอบ',
        sxf: 'ค่าธรรมเนียมการจัดการ',
        gg: 'ใช้ประโยชน์จากหลาย ๆ',
        yjpc: 'ปิดทั้งหมด',
        status: 'สถานะ',
    },
    auth: {
        title: 'การตรวจสอบตัวตน',
        title1: 'การตรวจสอบตัวตน',
        low: 'การรับรองความถูกต้องขั้นพื้นฐาน',
        high: 'การรับรองความถูกต้องขั้นสูง',
        success: 'ตรวจสอบเรียบร้อยแล้ว',
        tips: 'โปรดดำเนินการตรวจสอบสิทธิ์หลักก่อน',
        tips1: 'กรุณาใส่ชื่อจริงของคุณ',
        tips2: 'กรุณากรอกเลขประจำตัวประชาชน',
        tips3: 'กรุณาอัพโหลดบัตรประจำตัวประชาชน (ด้านหน้า)',
        tips4: 'กรุณาอัพโหลดบัตรประจำตัวประชาชนของคุณ (ด้านหลัง)',
        tips5: 'โปรดอัปโหลดรูปถ่ายของคุณที่ถือบัตรประจำตัวพร้อมหน้ารูปถ่าย',
        qrz: 'เพื่อยืนยัน',
        shz: 'ภายใต้การทบทวน',
        rzsb: 'การยืนยันล้มเหลว',
        userName: 'ชื่อจริง',
        IDNumber: 'หมายเลขประจำตัวประชาชน',
        zmz: 'รูปถ่ายบัตรประจำตัวประชาชน (ด้านหน้า)',
        bmz: 'รูปถ่ายติดบัตรประชาชน (ด้านหลัง)',
        scz: 'รูปถ่ายตัวเองถือบัตรประจำตัวที่มีรูปถ่ายหน้า'
    },
    share: {
        title: 'รหัสอ้างอิงของฉัน',
        cp: 'คัดลอกที่อยู่',
        success: 'คัดลอกสำเร็จ'
    },
    property: {
        Contract: 'จุด',
        Exchange: 'สัญญาที่สอง',
        zzc: 'สินทรัพย์รวม',
        mhyzh: 'บัญชีสัญญาที่สอง',
        bbzh: 'บัญชีสปอต',
        available: 'มีอยู่',
        OnOrders: 'แช่แข็ง',
        Estimated: 'เทียบเท่า',
        cw: 'บันทึกทางการเงิน',
        number: 'ตัวเลข',
        record: 'บันทึก',
        Transfer: 'โอนย้าย',
        time: 'เวลา',
        dhsl: 'จำนวนโทเค็นที่จะแลกเปลี่ยน',
        tips: 'โปรดป้อนจำนวนโทเค็นที่จะแลกเปลี่ยน',
        qbdh: 'สลับทั้งหมด',
        dhhl: 'อัตราแลกเปลี่ยน',
        ky: 'มีอยู่',
        kd: 'มีอยู่',
        sxfbl: 'ค่าธรรมเนียมการจัดการ (อัตรา)',
        sxf: 'ค่าธรรมเนียมการจัดการ'
    },
    statusLang: {
        Review: 'ทบทวน',
        Success: 'ความสำเร็จ',
        Fail: 'ล้มเหลว'
    },
    with: {
        title: 'ถอน',
        history: 'ประวัติศาสตร์',
        ldz: 'ที่อยู่บล็อกเชน',
        tbdz: 'ที่อยู่ถอน',
        tips: 'กรุณากรอกที่อยู่ในการถอนเงิน',
        tips1: 'กรุณากรอกจำนวนเงินที่ถอน',
        tips2: 'กรุณากรอกรหัสผ่านกองทุน',
        tbsl: 'จำนวนเงินที่ถอนออก',
        ky: 'มีอยู่',
        zjmm: 'รหัสผ่านกองทุน',
        sxf: 'ค่าธรรมเนียมการจัดการ',
        dz: 'จำนวนเงินที่คาดว่าจะได้รับ'
    },
    tabBar: {
        home: 'หน้าแรก',
        Quotation: 'คำพูด',
        Quotation2: 'ตลาด',
        Trade: 'สกุลเงิน',
        Contract: 'สัญญาที่สอง',
        assets: 'สินทรัพย์',
        qhyy: 'เปลี่ยนภาษา',
        zyjb: 'ล็อคการขุด',
        jsjy: 'การทำธุรกรรมที่รุนแรง',
        jryw: 'บริการทางการเงิน',
        hyjy: 'ธุรกรรมสัญญา',
        bbjy: 'การแลกเปลี่ยนเหรียญ'
    },
    home: {
        UserName: 'ชื่อผู้ใช้',
        score: 'คะแนน',
        kjmb: 'ซื้อด่วน',
        zc: 'รองรับ BTC, USDT ฯลฯ.',
        scwk: 'การขุดแบบล็อคขึ้น',
        sgxb: 'ใหม่ การสมัครสมาชิกโทเค็น',
        aqkx: 'ปลอดภัยและเชื่อถือได้',
        aqkx1: 'แพลตฟอร์มการซื้อขายสินทรัพย์ดิจิทัลชั้นนำของโลก',
        zfb: 'รายชื่อเหรียญที่เพิ่มขึ้น',
        dfb: 'รายการลดลง',
        mc: 'ชื่อ',
        zxj: 'ราคาล่าสุด',
        zdb: 'การเปลี่ยนแปลงสุทธิ',
        hy: 'การงัด',
        btyh: 'ผู้ใช้ปกติ',
        sfyz: 'การตรวจสอบตัวตน',
        skfs: 'วิธีการชำระเงิน',
        share: 'ลิงค์แบ่งปันของฉัน',
        kjcb: 'ฝากด่วน',
        kjtb: 'ถอนด่วน',
        qbdz: 'ที่อยู่กระเป๋าเงิน',
        bps: 'กระดาษสีขาว',
        xgmm: 'เปลี่ยนรหัสผ่าน',
        xgzfmm: 'เปลี่ยนรหัสผ่านกองทุน',
        gywm: 'เกี่ยวกับ',
        tcdl: 'ปิดบัญชี'
    },
    quotation: {
        title: 'ตลาด',
        mc: 'ปริมาณ',
        zxj: 'ราคาล่าสุด',
        zdb: 'การเปลี่ยนแปลงสุทธิ',
        liang: 'ปริมาณ'
    },
    contract: {
        kcslfw: 'เปิดช่วงปริมาณ:',
        gao: 'สูง',
        di: 'ต่ำ',
        liang: 'ปริมาณ 24 ชม',
        placeholder: 'กรุณากรอกจำนวนเงิน',
        SuccessfulPurchase: 'ซื้อสำเร็จ',
        smallMin: 'นาที',
        hour: 'ชั่วโมง',
        dayMin: 'วัน',
        Trend: 'เวลา',
        ccjl: 'รายการโฮลดิ้ง',
        ddjl: 'รายการสั่งซื้อ',
        sl: 'ปริมาณ',
        gmj: 'ราคาซื้อ',
        dqj: 'ราคาปัจจุบัน',
        yjyk: 'กำไรขาดทุนโดยประมาณ',
        djs: 'นับถอยหลัง',
        gbjg: 'ราคานัดหยุดงาน',
        sc: 'ค่าธรรมเนียมการจัดการ',
        pal: 'กำไรและขาดทุน',
        mhy: 'สัญญาที่สอง',
        sj: 'เวลา',
        yll: 'อัตรากำไร',
        mz: 'ยาว',
        md: 'สั้น',
        kcsl: 'ปริมาณตำแหน่งที่เปิด',
        kcsj: 'เวลาเปิดตำแหน่ง',
        zhye: 'ยอดเงินในบัญชี',
        qc: 'ยกเลิก'
    },
    editPassword: {
        title: 'เปลี่ยนรหัสผ่าน',
        jmm: 'รหัสผ่านเก่า',
        xmm: 'รหัสผ่านใหม่',
        qrmm: 'ยืนยันรหัสผ่าน',
        placeholder: 'รหัสผ่านเก่า',
        placeholder1: 'ตั้งรหัสผ่านใหม่',
        placeholder2: 'กรุณาใส่รหัสผ่านใหม่อีกครั้ง',
        qrxf: 'ตกลง'
    },
    withPass: {
        title: 'รหัสผ่านถอนเงิน',
        jmm: 'รหัสผ่านเก่า',
        xmm: 'รหัสผ่านใหม่',
        qrmm: 'ยืนยันรหัสผ่าน',
        placeholder: 'รหัสผ่านเก่า',
        placeholder1: 'ตั้งรหัสผ่านใหม่',
        placeholder2: 'กรุณาใส่รหัสผ่านใหม่อีกครั้ง',
        qrxf: 'ตกลง',
        tips: 'กรุณาใส่รหัสผ่านเก่าอย่างน้อย 6 หลัก',
        tips1: 'กรุณาใส่รหัสผ่านใหม่อย่างน้อย 6 หลัก',
        tips2: 'กรุณาใส่รหัสผ่านยืนยันของคุณ',
        tips3: 'การป้อนรหัสผ่านใหม่สองรายการไม่สอดคล้องกัน',
        tips4: ' ตั้งรหัสผ่านสำเร็จแล้ว',
        confirm: 'ยืนยัน'
    },
    newBe: {
        title: 'การสมัครสมาชิก',
        title1: 'การสมัครของฉัน',
        scqx: 'ช่วงล็อคอัพ',
        day: 'วัน',
        sy: 'ที่เหลืออยู่',
        sdsg: 'ประวัติศาสตร์',
        sysl: 'ปริมาณคงเหลือ',
        gmsl: 'ปริมาณการซื้อ',
        dj: 'ราคาต่อหน่วย',
        tips: 'กรุณากรอกจำนวนซื้อ',
        gmzj: 'ราคาซื้อทั้งหมด',
        qbye: 'ยอดคงเหลือในกระเป๋าเงิน',
        fmzj: 'ราคาซื้อทั้งหมด',
        gm: 'ซื้อ',
        sgsj: 'เวลาสมัครสมาชิก',
        Currency: 'ประเภทโทเค็น',
        Request: 'จำนวนเงินที่ขอ',
        Passing: 'จำนวนเงินที่ผ่าน',
        MarketTime: 'รายการเวลา',
        sqz: 'ในแอปพลิเคชัน'
    },
    register: {
        title: 'ทะเบียน',
        lang: 'ภาษา',
        send: 'ส่ง',
        Resend: 'ส่งซ้ำ',
        Account: 'บัญชี',
        Password: 'รหัสผ่าน',
        ConfirmPassword: 'ยืนยันรหัสผ่าน',
        InvitationCode: 'รหัสการเชิญ',
        btn: 'ลงทะเบียน',
        amount: 'บัญชี',
        mailbox: 'ตู้จดหมาย',
        haveAccount: 'มีบัญชีอยู่แล้ว？',
        Login: 'เข้าสู่ระบบ',
        placeholder: 'กรุณากรอกอีเมล์',
        placeholder0: 'กรุณากรอกบัญชี',
        placeholder1: 'กรุณากรอกรหัสผ่าน',
        placeholder2: 'กรุณาใส่รหัสผ่านอีกครั้ง',
        placeholder3: 'กรุณากรอกรหัสเชิญ',
        placeholder4: 'กรุณากรอกรหัสยืนยันอีเมล์',
        placeholder5: 'กรุณาใส่กล่องจดหมายที่ถูกต้อง',
        success: 'ลงทะเบียนสำเร็จ',
        message: 'รหัสผ่านต้องมีความยาว 6-16 ตัวอักษร',
        message1: 'ยืนยันการป้อนรหัสผ่านมีอักขระอย่างน้อย 6 ตัว',
        message2: 'การป้อนรหัสผ่านไม่สอดคล้องกัน',
        message3: 'โปรดอ่านและทำเครื่องหมายข้อตกลง',
        agree: 'ฉันเห็นด้วย',
        xy: 'ข้อตกลงผู้ใช้และนโยบายความเป็นส่วนตัว',
        qr: 'ส่ง'
    },
    lockming: {
        title: 'การล็อคโทเค็น',
        wkb: 'การขุดสมบัติ',
        wkgjd: 'การขุดนั้นง่ายกว่า',
        qsljl: 'รับรางวัลได้ง่ายๆ',
        yjzsr: 'รายได้รวมโดยประมาณ',
        ck: 'อ้างอิงอัตราผลตอบแทนต่อปีในช่วงสามวันที่ผ่านมา',
        tips: 'ขั้นต่ำ',
        rlx: 'ดอกเบี้ยรายวัน',
        day: 'วัน',
        scqx: 'ช่วงปิดเทอม',
        buy: 'ซื้อ',
        zysl: 'จำนวนเงินเดิมพัน',
        qbye: 'ยอดคงเหลือในกระเป๋าเงิน',
        finished: 'สมบูรณ์',
        doing: 'อยู่ระหว่างดำเนินการ',
        sl: 'ปริมาณ',
        shouyilv: 'ประเมินค่า',
        ks: 'เวลาเริ่มต้น',
        js: 'เวลาสิ้นสุด'
    },
    login: {
        title: 'เข้าสู่ระบบ',
        lang: 'ภาษา',
        Account: 'บัญชี',
        Password: 'รหัสผ่าน',
        btn: 'เข้าสู่ระบบ',
        placeholder1: 'กรุณาใส่รหัสผ่าน',
        placeholder: 'กรุณากรอกเลขที่บัญชี',
        fail: 'กรุณากรอกแบบฟอร์ม',
        ForgotPassword: 'ลืมรหัสผ่าน',
        NoAccount: 'ยังไม่มีบัญชี？',
        Registration: 'ลงทะเบียน',
        OtherLogin: 'เข้าสู่ระบบอื่น ๆ',
        server: 'ฝ่ายบริการลูกค้าออนไลน์',
        jzmm: 'จดจำรหัสผ่าน',
        success: 'เข้าสู่ระบบสำเร็จ',
        ageent: 'อ่านและเห็นด้วยกับ',
        ageentTitle: 'สัญญาการให้บริการ',
        ageentFail: 'โปรดอ่านและตรวจสอบข้อตกลงการใช้บริการ',
        Home: 'บ้าน'
    },
    moneyAddr: {
        title: 'ที่อยู่กระเป๋าสตางค์',
        Currency: 'สกุลเงิน',
        Address: 'ที่อยู่',
        placeholder: 'โปรดเลือก',
        DepositAddress: 'ที่อยู่ฝาก',
        WalletAddress: 'ที่อยู่กระเป๋าสตางค์',
        Date: 'วันที่',
        add: 'เพิ่มที่อยู่กระเป๋าเงิน',
        ChainAddress: 'ที่อยู่ลูกโซ่',
        qrcode: 'รหัส QR กระเป๋าสตางค์',
        submit: 'ส่ง',
        tips: 'กรุณาเลือกประเภทกระเป๋าสตางค์',
        tips1: 'กรุณาเลือกที่อยู่ในเครือ',
        tips2: 'กรุณากรอกที่อยู่กระเป๋าเงิน',
        tips3: 'โปรดอัปโหลดรหัส QR กระเป๋าเงิน',
        success: 'ความสำเร็จ'
    },
    recharge: {
        title: 'เติมเงิน',
        title1: 'รายละเอียดการเติมเงิน',
        ls: 'ประวัติศาสตร์',
        td: 'กรุณาเลือกช่องดังต่อไปนี้',
        bc: 'บันทึกคิวอาร์',
        address: 'ที่อยู่ฝาก',
        copy: 'คัดลอกที่อยู่',
        num: 'จำนวนเงินฝาก',
        Upload: 'อัปโหลดรูปภาพ',
        Submit: 'ส่ง',
        tip: 'กรุณาระบุจำนวนเงิน',
        tip1: 'กรุณาอัปโหลดรหัส QR',
        copySuc: 'คัดลอกสำเร็จ'
    },
    newChange: {
        num: 'กรุณาใส่ปริมาณ',
        psd: 'กรุณาใส่รหัสผ่าน',
        hq: 'คำพูด',
        bb: 'ศูนย์ช่วยเหลือ',
        sec: 'ตรวจสอบแล้ว',
        ass: 'สินทรัพย์',
        pool: 'การจัดการทางการเงิน',
        newc: 'เกี่ยวกับเรา',
        rechange: 'เติมเงิน',
        with: 'บริการลูกค้า',
        a1: 'การเงิน',
        a2: 'Fnds in custody(USDT)',
        a3: 'กำไรวันนี้',
        a4: 'รายได้สะสม',
        a5: 'กำลังดำเนินการ',
        a6: 'คำสั่งซื้อ',
        a7: 'กฎ',
        a8: 'ผลตอบแทนรายวัน',
        a9: 'ขีดจำกัดเดียว',
        a10: 'วงจร',
        a11: 'Pecently (รายได้รายวัน)',
        a12: 'ขีดจำกัดเดียว',
        a13: 'จำนวนการดูแล：',
        a14: 'สมัครสมาชิก',
        a15: 'การคำนวณรายได้',
        a16: 'Bricks ถูกย้ายโดยการโฮสต์ USDT ไปที่แพลตฟอร์ม และแพลตฟอร์มคือทีมงานมืออาชีพที่ดำเนินการหากำไรจากการย้าย Bricks...',
        a17: 'สมาชิกคนหนึ่งฝากเงิน 10,000U บนแพลตฟอร์ม และเลือกผลิตภัณฑ์การจัดการความมั่งคั่งที่มีระยะเวลา 5 วันและอัตราผลตอบแทนรายวัน 0.3% ถึง 0.5% สูงสุดรายวัน: 10,000U x 0.5% = 50 U n That คือรายได้ 150U-250U สามารถรับได้หลังจาก 5 วัน รายได้จะออกทุกวัน และสามารถเข้าถึงรายได้ที่ออกได้ตลอดเวลา เงินต้นที่ฝากไว้หมดอายุหลังจากนั้นจะเป็น ...',
        a18: 'เกี่ยวกับความเสียหายที่ชำระบัญชี',
        a19: 'หากคุณต้องการโอนเงินต้นที่ยังไม่หมดอายุ ความเสียหายจากการชำระบัญชีจะเกิดขึ้น ความเสียหายจากการชำระบัญชี = อัตราส่วนการชำระบัญชีเริ่มต้น * จำนวนวันที่เหลือ * จำนวนเงินลงทุน Nตัวอย่าง:ผลิตภัณฑ์เป็นอัตราส่วนการชำระหนี้เริ่มต้นคือ 0.4% ครบกำหนดในอีก 3 วันที่เหลือ จำนวนเงินลงทุนคือ 1,000 จากนั้นชำระค่าเสียหาย = 0.4%*3...',
        a20: 'ฉันต้องการเข้าร่วม',
        help: 'ศูนย์ช่วยเหลือ',
    },
    msgbank: {
        msgTitle: 'ข้อความ',
        noticeTitle: 'ประกาศ',
        msgContent: 'คุณมีข้อความที่ยังไม่ได้อ่าน',
        msgBtn: 'ไปดู',
        bankCard: 'บัตรธนาคาร',
        inputTips: 'กรุณากรอกข้อมูลทั้งหมด',
        submitSuccess: 'ส่งเรียบร้อย',
        CardUserName: 'ชื่อผู้เปิดบัญชี',
        BankName: 'เปิดบัญชีธนาคาร',
        OpenBank: 'สาขาเปิดบัญชี',
        CardNo: 'หมายเลขบัตรธนาคาร',
        noBindBankmsg: 'คุณยังไม่ได้ผูกบัตรธนาคาร',
        noBindBankBtn: 'การผูกมัด',
        modify: 'การปรับเปลี่ยน',
    }
}