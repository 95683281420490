module.exports = {
    pcregister: {
        p1: 'Sudah memiliki akun?',
        dl: 'Gabung',
        zc: 'daftar',
        p4: 'Akun',
        p5: 'silahkan masuk ke akun',
        p6: 'silakan masukkan kata sandi',
        p7: 'Silakan masukkan kata sandi konfirmasi',
        p8: 'Silakan masukkan kode undangan',
        p9: 'saya setuju',
        p10: '"Perjanjian Pengguna dan Kebijakan Privasi"',
        p11: 'Surat',
        p12: 'silahkan masukan email anda',
        p13: 'Silakan masukkan kode verifikasi email',
        p14: 'Kirim',
        p15: 'Tahun',
        p16: 'bulan',
        p17: 'langit',
        p18: 'Jam',
        p19: 'menit',
        p20: 'kedua'
    },
    pclogin: {
        l1: 'Tidak ada akun?',
        l2: 'daftar',
        l3: 'Gabung',
        l4: 'silakan masukkan nama pengguna',
        l5: 'silakan masukkan kata sandi',
        l6: 'Tidak ingat kata sandi',
        l7: 'Gabung'
    },
    pccontract: {
        ss: 'harga pasar',
        xj: 'batas harga',
        gg: 'tuas'
    },
    pcrapidly: {
        zf: 'meningkat',
        dd: 'Memesan',
        bdsj: 'waktu lokal',
        ky: 'tersedia'
    },
    pchome: {
        global1: 'Platform perdagangan aset digital terkemuka di dunia',
        global2: 'Ini memberikan transaksi aset digital dan layanan manajemen aset yang aman dan tepercaya kepada jutaan pengguna di lebih dari 100 negara.',
        global3: 'Aman dan terpercaya',
        global4: 'Lima tahun pengalaman dalam layanan keuangan aset digital. Fokus pada arsitektur terdistribusi dan sistem serangan anti-DDoS.',
        global5: 'Tata letak ekologi global',
        global6: 'Pusat layanan transaksi lokal telah didirikan di banyak negara untuk menciptakan ekosistem blockchain yang mengintegrasikan berbagai format bisnis.',
        global7: 'pengguna terlebih dahulu',
        global8: 'Tetapkan mekanisme kompensasi di muka dan siapkan dana perlindungan investor.',
        whever1: 'Transaksi terminal multi-platform kapan saja, di mana saja',
        whever2: 'Mencakup berbagai platform termasuk iOS, Android, dan Windows, dan mendukung fungsi bisnis penuh.',
        register1: 'Daftarkan a',
        register3: 'Akun global mulai berdagang',
        register2: 'Daftar sekarang'
    },
    pcbottom: {
        box1: 'Terkemuka di dunia',
        box2: 'salah satu tim terbaik',
        box3: 'Lagi',
        box4: 'tentang kami',
        box5: 'kertas putih',
        box6: 'Melayani',
        box7: 'unduh',
        box8: 'masalah umum',
        box9: 'layanan daring',
        box10: 'Peraturan',
        box11: 'Perjanjian Pengguna',
        box12: 'Kebijakan pribadi',
        box13: 'Pemberitahuan Hukum',
        box14: 'Menghubung'
    },
    pcAbout_us: {
        t1: 'kursus',
        t2: 'Kami, 7 tahun jalan',
        t3: 'Sejak kelahirannya pada tahun 2015, kami telah mengabdikan diri untuk melayani blockchain dengan teknologi penuh waktu dan layanan konstruksi saluran.',
        t4: '2014-Pada tahun 2017, mengalami periode kenaikan "stabil dan meningkat", dan pada tahun 2017-Paruh pertama tahun 2018 adalah periode gila "beli uang dan menjadi kaya" Dari paruh kedua tahun 2019 hingga periode musim dingin saat ini, saya telah melatih keterampilan saya yang sebenarnya. Banyak mitra sukses telah memberi kami reputasi rendah di industri ini. Pada tahun 2022, kami akan memiliki platform baru V3.0, dan mendirikan perusahaan saham gabungan baru, BitGoldEX, untuk menjalankan bisnis di lebih dari 100 negara di seluruh dunia.',
        t5: 'Siapa yang kita cari',
        t6: 'Dari awal sampai hari ini',
        t7: 'Saya telah mencari penyedia layanan yang mengalami kesulitan dan memiliki selera yang sama dengan saya, dan telah berhasil memecahkan masalah puluhan penyedia layanan dalam beberapa tahun.. ',
        t8: 'Dalam rasionalitas hari ini, jika Anda ingin menemukan penolong yang baik, pasangan yang dapat bekerja sama untuk menyadari diri sendiri dan melanjutkan hidup—mungkin, Anda dapat berbicara dengan kami.',
        t9: 'Harap percaya bahwa orang yang Anda cari telah mencari Anda.',
        t10: 'Melayani',
        t11: 'Tidak ada penjual, tetapi ada bisnis',
        t12: 'Ketika seorang teman memperkenalkan Anda pada bisnis, itu berarti Anda adalah orang yang baik. Jika rekan Anda memperkenalkan bisnis kepada Anda, itu berarti Anda memiliki reputasi yang baik. Ketika klien memperkenalkan bisnis kepada Anda, itu membuktikan bahwa Anda dapat dipercaya.',
        t13: 'Roh',
        t14: 'Kami batu bata, tidak perlu pergi ke mana',
        t15: 'Pelanggan yang berbeda memiliki persyaratan yang berbeda. Produk yang berbeda memiliki karakteristik yang berbeda. Waktu yang berbeda memiliki tujuan yang berbeda. Arti keberadaan kami adalah untuk beradaptasi dengan konsep, sistem, dan persyaratan penyedia layanan yang berbeda dan menyelesaikan berbagai masalah.',
        t16: 'Kami bersedia menjadi batu bata dengan pemikiran dan fungsi, di mana pun ada kebutuhan, kami akan pergi ke sana.'
    },
    pcproblem: {
        p1: 'masalah umum',
        p2: 'Mengapa jumlah aset yang dikonversi berubah?',
        p3: 'Perhitungan ekuivalen dalam aset adalah nilai mata uang digital yang dipegang saat ini yang diubah menjadi USDT, yang berubah karena fluktuasi harga aset digital. Jumlah aset digital Anda tidak berubah.',
        p4: 'Cara mengatasi lupa password dana dalam transaksi platform',
        p5: 'Jika Anda lupa kata sandi dana transaksi platform, silakan buka "My-mempersiapkan-Klik "Tetapkan Kata Sandi Dana" atau hubungi layanan pelanggan untuk mengatur ulang',
        p6: 'Kemana perginya uang setelah transaksi pembelian?',
        p7: 'Setelah pesanan pembelian selesai, dana akan masuk ke akun mata uang fiat di aset. Jika Anda perlu membeli aset untuk perdagangan kontrak atau perdagangan berjangka, silakan buka modul transfer dana untuk mentransfer.',
        p8: 'Mengapa membatasi jumlah perubahan nama panggilan',
        p9: 'Demi keamanan transaksi Anda, saat ini kami membatasi nickname hanya boleh dimodifikasi satu kali.',
        p10: 'Apa yang dimaksud dengan pengalihan? mengapa transfer',
        p11: 'Untuk memastikan independensi dana antara akun Anda dan untuk memfasilitasi pengendalian risiko yang wajar, kami dengan ini membagi akun dari setiap modul perdagangan utama. Transfer mengacu pada proses konversi aset antar akun trading.',
        p12: 'Bagaimana ekuitas akun dari kontrak pengguna dihitung?',
        p13: 'Nilai bersih akun kontrak = margin posisi + keuntungan dan kerugian mengambang posisi + jumlah akun saat ini tersedia',
        p14: 'Berapa nilai pengaturan Stop Loss dan Take Profit saat membuka posisi? Bagaimana cara mengaturnya?',
        p15: 'Ambil untung dan rugi sebagai batas atas untung dan rugi Anda. Ketika jumlah stop profit dan stop loss yang ditetapkan tercapai, sistem akan menutup posisi secara otomatis. Ini dapat digunakan untuk pengendalian risiko saat membeli kontrak. Tetapkan setengah dari jumlah take profit: tingkatkan jumlah x kuantitas x kelipatan leverage, atur stop loss. Kami menyarankan Anda untuk mengaturnya sesuai dengan aset Anda yang sebenarnya dan mengendalikan risiko secara wajar.',
        p16: 'Bagaimana cara mengurangi risiko kontrak?',
        p17: 'Anda dapat mentransfer aset yang tersedia dari akun yang tersisa ke akun kontrak dengan mentransfer dana, atau mengurangi risiko dengan mengurangi posisi',
        p18: 'Apa yang dimaksud dengan kuantitas pembukaan kontrak?',
        p19: 'Jumlah bunga terbuka mewakili jumlah mata uang yang ingin Anda beli. Contoh: dalam BTC/Di halaman pembuka pasangan perdagangan USDT, pilih long, masukkan harga sebagai 1000USDT, dan masukkan kuantitas sebagai 10BTC, yaitu, Anda berharap untuk membeli 10 BTC dengan harga satuan 1000USDT.',
        p20: 'Bagaimana biaya penanganan dalam kontrak dihitung?',
        p21: 'Biaya penanganan = harga pembukaan * kuantitas pembukaan * tingkat biaya penanganan',
        p22: 'Tindakan pencegahan untuk likuidasi paksa',
        p23: 'Tingkat risiko adalah ukuran risiko aset Anda. Ketika tingkat risiko sama dengan 100%, posisi Anda dianggap ditutup, tingkat risiko = (memegang margin / ekuitas akun kontrak) * 100%, untuk mencegah pengguna melampaui posisi, sistem menyesuaikan rasio tingkat risiko. Ketika tingkat risiko mencapai nilai risiko yang ditetapkan oleh sistem, sistem akan memaksa posisi ditutup. Misalnya: rasio penyesuaian yang ditetapkan oleh sistem adalah 10%, maka ketika tingkat risiko Anda lebih besar atau sama dengan 90%, maka semua posisi Anda akan ditutup paksa oleh sistem.',
        p24: 'Apa aturan perdagangan kontrak?',
        p25: 'tipe transaksi',
        p26: 'Jenis transaksi dibagi menjadi arah panjang (beli) dan pendek (jual):',
        p27: 'Membeli panjang (bullish) berarti Anda percaya bahwa indeks saat ini cenderung naik, dan Anda ingin membeli sejumlah tertentu untuk masuk ke dalam kontrak pada harga yang Anda tetapkan atau harga pasar saat ini.',
        p28: 'Short selling (bearish) berarti Anda percaya bahwa indeks saat ini mungkin turun dan ingin menjual sejumlah kontrak baru dengan harga yang Anda tetapkan atau harga pasar saat ini.',
        p29: 'Metode pesanan',
        p30: 'Limit order: Anda perlu menentukan harga dan jumlah pesanan',
        p31: 'Pesanan pasar: Anda hanya perlu mengatur jumlah pesanan, dan harganya adalah harga pasar saat ini',
        p32: 'posisi',
        p33: 'Saat pesanan yang Anda kirimkan untuk membuka posisi selesai, itu disebut posisi',
        p34: 'Masalah Pengiriman Kontrak',
        p35: 'Kontrak platform adalah kontrak abadi tanpa waktu pengiriman. Selama sistem tidak memenuhi kondisi likuidasi paksa atau Anda tidak menutup posisi secara manual, Anda dapat menahan posisi tersebut secara permanen. Tutup sistem',
        p36: '1: Sistem secara otomatis menutup posisi ketika nilai stop profit dan stop loss yang ditetapkan tercapai',
        p37: '2: Risikonya terlalu tinggi, sistem memaksa likuidasi',
        p38: 'Berapa margin untuk perdagangan kontrak?',
        p39: 'Dalam perdagangan kontrak, pengguna dapat berpartisipasi dalam perdagangan kontrak sesuai dengan harga dan jumlah kontrak, serta leverage yang sesuai. Saat pengguna membuka posisi, margin akan ditempati. Semakin besar margin pembukaan, semakin tinggi risiko akun.',
        p40: 'Apa itu limit order dan market order?',
        p41: 'Perintah batas mengacu pada harga yang Anda harapkan untuk mempercayakan platform untuk melakukan transaksi, dan pesanan pasar mengacu pada mempercayakan platform secara langsung untuk melakukan transaksi dengan harga saat ini. Order pasar lebih diutamakan daripada limit order dalam aturan pembukaan order. Jika Anda memilih limit order, harap buka posisi secara wajar sesuai dengan harga pasar mata uang saat ini untuk menghindari kerugian yang disebabkan oleh harga pembukaan yang tidak wajar.',
        p42: 'Apa yang diwakili oleh tingkat risiko dari transaksi kontrak?',
        p43: 'Risiko adalah indikator risiko di akun kontrak Anda. Tingkat risiko sama dengan 100% dianggap dilikuidasi. Kami menyarankan ketika risiko Anda melebihi 50%, Anda perlu membuka posisi dengan hati-hati untuk menghindari kerugian yang disebabkan oleh penutupan posisi. Anda dapat mengurangi risiko dengan mengisi kembali dana yang tersedia dalam aset kontrak atau dengan mengurangi posisi Anda. ',
        p44: 'Mengapa Anda membutuhkan penukaran mata uang?',
        p45: 'Tujuan pertukaran adalah untuk memungkinkan kelancaran sirkulasi dana dalam berbagai mata uang dalam aset Anda, dan QCC yang diperoleh di akun berjangka dapat secara bebas dikonversi menjadi USDT untuk diperdagangkan. USDT di akun lain juga dapat dengan bebas dikonversi menjadi QCC untuk transaksi. ',
        p46: 'Mengapa pihak lain tidak menerima transfer tepat waktu setelah transfer?',
        p47: 'Pertama-tama, pastikan metode pembayaran yang Anda isi saat mentransfer uang sama persis dengan metode pembayaran yang ditampilkan di halaman detail pesanan. Kemudian konfirmasikan apakah metode pembayaran Anda adalah pembayaran segera atau pembayaran tertunda. Terakhir, harap hubungi bank dan lembaga pembayaran Anda untuk pemeliharaan sistem atau alasan lainnya. ',
        p48: 'Bagaimana cara membeli USDT melalui platform?',
        p49: 'Metode 1: Pilih pesanan yang ingin Anda beli melalui daftar beli dan jual platform untuk membeli atau menjual.',
        p50: 'Metode 2: Klik tombol terbitkan untuk menerbitkan pesanan beli atau jual sesuai kebutuhan Anda untuk transaksi cepat. Sistem akan dengan cepat mencocokkan Anda dengan pembeli dan penjual.',
        p51: 'Catatan: Jika pesanan pembelian tidak dibayar setelah 15 menit, sistem akan membatalkan pesanan secara otomatis, harap bayar tepat waktu. Jika pesanan dibatalkan lebih dari 3 kali pada hari yang sama, pesanan tidak dapat diperdagangkan kembali pada hari yang sama, dan otoritas perdagangan akan dipulihkan pada hari berikutnya.'
    },
    public: {
        zxkf: 'layanan daring',
        sure: 'Konfirmasi',
        cancel: 'Membatalkan',
        RefreshSucceeded: 'Berhasil menyegarkan',
        noMoreData: 'tidak ada data lagi',
        pullingText: 'tarik ke bawah untuk menyegarkan ...',
        ReleaseText: 'lepaskan untuk menyegarkan ...',
        loadingText: 'Memuat ...',
        noData: 'Tidak ada catatan',
        xq: 'detail',
        submit: 'Konfirmasi',
        kefu: 'layanan daring'
    },
    ChangeType: {
        Upper: 'Isi ulang sistem',
        Lower: 'Penarikan sistem',
        Win: 'laba',
        Bet: 'menginvestasikan',
        BuyMining: 'pertambangan',
        CoinWin: 'Keuntungan SGD',
        SettleMining: 'Pengembalian pokok pertambangan',
        GiveInterest: 'Pendapatan pertambangan',
        BuyCoin: 'SGD tidak menang',
        BuyCoinBack: 'Pengembalian Langganan SGD',
        UsdtToBtc: 'USD ke Btc',
        UsdtToEth: 'Usdt ke Et',
        BtcToUsdt: 'Btc ke Usdt',
        BtcToEth: 'Btc ke Et',
        EthToUsdt: 'Et ke Usdt',
        EthToBtc: 'Eth ke Btc',
        CoinFrozen: 'Pembekuan lotre mata uang baru',
        CoinThaw: 'SGD mencairkan',
        CoinCancel: 'Pembatalan Pesanan Mata Uang',
        CoinBuy: 'Beli mata uang',
        CoinBuyEntrust: 'Pesanan Beli Koin',
        CoinSellEntrust: 'Pesanan penjualan token',
        CoinSell: 'Jual mata uang',
        ContractBuy: 'Perintah kontrak, pengurangan margin',
        ContractCharge: 'Pesanan kontrak, pengurangan biaya penanganan',
        ContractCancelToBond: 'Pembatalan Kontrak Pengembalian Uang Jaminan',
        ContractCancelToCharge: 'Biaya pengembalian dana pembatalan kontrak',
        ContractCloseProfit: 'Pendapatan likuidasi kontrak'
    },
    trade: {
        title: 'Mata uang',
        Buy: 'membeli',
        Sell: 'menjual',
        pc: 'menutup posisi',
        cb: 'biaya',
        xj1: 'harga sekarang',
        yk: 'laba rugi',
        xianjia: 'batas harga',
        jiage: 'harga',
        shuliang: 'kuantitas',
        keyong: 'tersedia',
        yonghuchicang: 'Posisi pengguna',
        fxlts: 'Tingkat risiko = margin posisi/ekuitas pelanggan dikalikan 100%, di mana ekuitas pelanggan sama dengan dana akun yang tersedia + margin transaksi awal + keuntungan dan kerugian mengambang',
        fxl: 'tingkat risiko',
        dqwt: 'komisi saat ini',
        all: 'semua',
        name: 'nama',
        shijian: 'waktu',
        zyjg: 'Trading dengan harga terbaik saat ini',
        caozuo: 'beroperasi',
        xj: 'batas harga',
        cc: 'posisi',
        leixing: 'Tipe',
        long: 'melakukan lebih',
        short: 'pendek',
        sj: 'harga pasar',
        jiaoyie: 'jumlah transaksi',
        orderNo: 'jumlah order',
        weituojia: 'harga komisi',
        chengjiaojia: 'harga akhir',
        chengjiaoe: 'pergantian',
        weituo: 'Metode amanah',
        qbwt: 'percayakan semua',
        lswt: 'komisi sejarah',
        tips: 'Masukkan harga yang benar',
        tips1: 'Masukkan jumlahnya',
        Create: 'dipercayakan',
        Wait: 'dalam transaksi',
        Complete: 'Posisi tertutup',
        Cancel: 'Dibatalkan',
        zsj: 'Hentikan harga',
        zyj: 'Ambil Harga Untung',
        cczsy: 'Posisi keuntungan total',
        ykbl: 'Rasio untung dan rugi',
        bili: 'Proporsi',
        jyss: 'Banyak perdagangan',
        bzj: 'Batas',
        sxf: 'Biaya penanganan',
        gg: 'Manfaat',
        yjpc: 'Tutup posisi dengan satu klik',
        status: 'keadaan',
    },
    auth: {
        title: 'Autentikasi',
        title1: 'Autentikasi',
        low: 'Sertifikasi primer',
        high: 'Sertifikasi Lanjutan',
        success: 'Otentikasi berhasil',
        tips: 'Silakan lakukan sertifikasi primer terlebih dahulu',
        tips1: 'silahkan masukkan nama asli anda',
        tips2: 'Silakan masukkan nomor ID',
        tips3: 'Unggah foto depan KTP Anda',
        tips4: 'Unggah foto bagian belakang KTP Anda',
        tips5: 'Unggah foto genggam',
        qrz: 'pergi ke otentikasi',
        shz: 'sedang ditinjau',
        rzsb: 'Otentikasi gagal',
        userName: 'nama sebenarnya',
        IDNumber: 'nomor identitas',
        zmz: 'foto depan KTP',
        bmz: 'Foto bagian belakang KTP',
        scz: 'foto tangan'
    },
    share: {
        title: 'kode promo saya',
        cp: 'alamat salinan',
        success: 'berhasil menyalin'
    },
    property: {
        Contract: 'Mata uang',
        Exchange: 'kontrak kedua',
        zzc: 'Setara total aset',
        mhyzh: 'Akun kontrak kedua',
        bbzh: 'rekening mata uang',
        available: 'tersedia',
        OnOrders: 'membekukan',
        Estimated: 'mengubah',
        cw: 'catatan keuangan',
        number: 'kuantitas',
        record: 'Catatan',
        Transfer: 'transfer',
        time: 'waktu',
        dhsl: 'jumlah pertukaran',
        tips: 'Masukkan jumlah penukaran',
        qbdh: 'tebus semua',
        dhhl: 'kurs',
        ky: 'tersedia',
        kd: 'Tersedia',
        sxfbl: 'Rasio biaya',
        sxf: 'Biaya penanganan'
    },
    statusLang: {
        Review: 'tinjauan',
        Success: 'kesuksesan',
        Fail: 'gagal'
    },
    with: {
        title: 'menarik',
        history: 'Catatan penarikan',
        ldz: 'alamat rantai',
        tbdz: 'Alamat penarikan',
        tips: 'Masukkan alamat penarikan',
        tips1: 'Silakan masukkan jumlah penarikan',
        tips2: 'Silakan masukkan kata sandi pembayaran',
        tbsl: 'Jumlah penarikan',
        ky: 'tersedia',
        zjmm: 'sandi pembayaran',
        sxf: 'Biaya penanganan',
        dz: 'Perkiraan jumlah'
    },
    tabBar: {
        home: 'halaman Depan',
        Quotation: 'kutipan',
        Quotation2: 'Pasar',
        Trade: 'Mata uang',
        Contract: 'Mata uang',
        assets: 'aktiva',
        qhyy: 'beralih bahasa',
        zyjb: 'Kunci penambangan',
        jsjy: 'Transaksi ekstrem',
        jryw: 'Layanan keuangan',
        hyjy: 'Transaksi kontrak',
        bbjy: 'Transaksi koin'
    },
    home: {
        UserName: 'Akun',
        kjmb: 'Beli koin dengan cepat',
        score: 'nilai kredit',
        zc: 'Mendukung BTC, USDT, dll.',
        scwk: 'Kunci penambangan',
        sgxb: 'Langganan SGD',
        aqkx: 'aman dan terpercaya',
        aqkx1: 'Platform perdagangan aset digital terkemuka di dunia',
        zfb: 'Dapatkan daftar',
        dfb: 'Daftar Jatuh',
        mc: 'nama',
        hy: 'kontrak',
        zxj: 'Harga terbaru',
        zdb: 'Perubahan kutipan',
        sfyz: 'Autentikasi',
        skfs: 'Cara Pembayaran',
        share: 'tautan berbagi saya',
        kjcb: 'Setoran cepat',
        kjtb: 'Penarikan cepat',
        qbdz: 'alamat dompet',
        bps: 'kertas putih',
        xgmm: 'ganti kata sandi',
        xgzfmm: 'Ubah kata sandi pembayaran',
        gywm: 'tentang kami',
        tcdl: 'berhenti'
    },
    quotation: {
        title: 'kutipan',
        mc: 'kuantitas',
        zxj: 'Harga terbaru',
        zdb: 'Perubahan kutipan',
        liang: 'kuantitas'
    },
    contract: {
        kcslfw: 'Kisaran Kuantitas Pembukaan:',
        gao: 'tinggi',
        di: 'Rendah',
        liang: 'volume 24 jam',
        placeholder: 'Masukkan jumlahnya',
        SuccessfulPurchase: 'pembelian sukses',
        smallMin: 'menit',
        hour: 'Jam',
        dayMin: 'langit',
        Trend: 'Berbagi waktu',
        ccjl: 'Daftar posisi',
        ddjl: 'catatan pesanan',
        sl: 'kuantitas',
        gmj: 'harga pembelian',
        dqj: 'harga sekarang',
        yjyk: 'Perkiraan untung dan rugi',
        djs: 'hitungan mundur',
        gbjg: 'harga akhir',
        sc: 'Biaya penanganan',
        pal: 'laba rugi',
        mhy: 'kontrak kedua',
        sj: 'waktu',
        yll: 'Profitabilitas',
        mz: 'memborong',
        md: 'beli pendek',
        kcsl: 'Kuantitas Pembukaan',
        kcsj: 'waktu pembukaan',
        zhye: 'Saldo rekening',
        qc: 'Membatalkan'
    },
    editPassword: {
        title: 'ganti kata sandi',
        jmm: 'password lama',
        xmm: 'kata sandi baru',
        qrmm: 'konfirmasi sandi',
        placeholder: 'password lama',
        placeholder1: 'atur kata sandi baru',
        placeholder2: 'Silakan masukkan kembali kata sandi baru',
        qrxf: 'Konfirmasi'
    },
    withPass: {
        title: 'Kata sandi penarikan',
        jmm: 'password lama',
        xmm: 'kata sandi baru',
        qrmm: 'kata sandi baru',
        placeholder: 'password lama',
        placeholder1: 'atur kata sandi baru',
        placeholder2: 'Silakan masukkan kembali kata sandi baru',
        qrxf: 'Konfirmasi',
        tips: 'Silakan masukkan kata sandi lama Anda dengan setidaknya 6 karakter',
        tips1: 'Silakan masukkan kata sandi baru minimal 6 karakter',
        tips2: 'Silakan masukkan kata sandi konfirmasi yang benar',
        tips3: 'Kata sandi baru yang dimasukkan dua kali tidak cocok',
        tips4: 'Kata sandi berhasil diatur',
        confirm: 'Konfirmasi'
    },
    newBe: {
        title: 'Langganan SGD',
        title1: 'langganan saya',
        scqx: 'Periode penguncian',
        day: 'langit',
        sy: 'tersisa',
        sdsg: 'Catatan',
        sysl: 'Jumlah yang tersisa',
        gmsl: 'Kuantitas pembelian',
        dj: 'patokan harga',
        tips: 'Masukkan jumlah pembelian',
        gmzj: 'jumlah harga pembelian',
        qbye: 'saldo dompet',
        fmzj: 'jumlah harga pembelian',
        gm: 'Membeli',
        sgsj: 'Waktu berlangganan',
        Currency: 'Mata uang',
        Request: 'Jumlah aplikasi',
        Passing: 'lulus kuantitas',
        MarketTime: 'waktu ke pasar',
        sqz: 'dalam aplikasi'
    },
    register: {
        title: 'daftar',
        lang: 'bahasa',
        send: 'Kirim',
        Resend: 'Kirim ulang',
        Account: 'Akun',
        Password: 'kata sandi',
        ConfirmPassword: 'konfirmasi sandi',
        InvitationCode: 'Kode undangan',
        btn: 'daftar',
        amount: 'Akun',
        mailbox: 'Surat',
        haveAccount: 'Sudah memiliki akun?',
        Login: 'Gabung',
        placeholder: 'silahkan masukan email anda',
        placeholder0: 'silahkan masuk ke akun',
        placeholder1: 'silakan masukkan kata sandi',
        placeholder2: 'Silakan masukkan kata sandi konfirmasi',
        placeholder3: 'Silakan masukkan kode undangan',
        placeholder4: 'Silakan masukkan kode verifikasi email',
        placeholder5: 'silahkan masukkan email anda yang valid',
        success: 'registrasi berhasil',
        message: 'Kata sandi minimal 6 karakter',
        message1: 'Konfirmasi kata sandi minimal 6 karakter',
        message2: 'dua kata sandi tidak cocok',
        message3: 'Harap baca dan centang perjanjian',
        agree: 'saya setuju',
        xy: 'Perjanjian Pengguna dan Kebijakan Privasi',
        qr: 'Konfirmasi'
    },
    lockming: {
        title: 'Daftar penguncian',
        wkb: 'harta tambang',
        wkgjd: 'Penambangan lebih mudah',
        qsljl: 'Mudah menerima hadiah',
        yjzsr: 'Perkiraan manfaat total',
        ck: 'Referensi tahunan selama 3 hari terakhir',
        tips: 'Paling sedikit ',
        rlx: 'bunga harian',
        day: 'langit',
        scqx: 'Periode penguncian',
        buy: 'Membeli',
        zysl: 'Jumlah janji',
        qbye: 'saldo dompet',
        finished: 'Menyelesaikan',
        doing: 'pengolahan...',
        sl: 'kuantitas',
        shouyilv: 'tingkat pengembalian',
        ks: 'Waktu mulai',
        js: 'Akhir waktu'
    },
    login: {
        title: 'Gabung',
        lang: 'bahasa',
        Account: 'Akun',
        Password: 'kata sandi',
        btn: 'Gabung',
        placeholder1: 'silakan masukkan kata sandi',
        placeholder: 'silahkan masuk ke akun',
        fail: 'Harap lengkapi formulirnya',
        ForgotPassword: 'Tidak ingat kata sandi',
        NoAccount: 'Belum punya akun?',
        Registration: 'daftar',
        server: 'layanan daring',
        jzmm: 'ingat kata Sandi',
        success: 'login berhasil',
        Home: 'halaman Depan'
    },
    moneyAddr: {
        title: 'alamat dompet',
        Currency: 'Mata uang',
        Address: 'alamat',
        placeholder: 'tolong pilih',
        DepositAddress: 'Alamat setoran',
        WalletAddress: 'alamat dompet',
        Date: 'waktu',
        add: 'Tambahkan alamat dompet',
        ChainAddress: 'alamat rantai',
        qrcode: 'Kode QR dompet',
        submit: 'Konfirmasi',
        tips: 'Silakan pilih jenis dompet',
        tips1: 'Harap pilih jenis alamat jaringan',
        tips2: 'Masukkan alamat dompet',
        tips3: 'Harap unggah kode QR dompet',
        success: 'kesuksesan'
    },
    recharge: {
        title: 'mengisi ulang',
        title1: 'Rincian Isi Ulang',
        ls: 'Rincian Isi Ulang',
        td: 'Silakan pilih saluran isi ulang berikut',
        bc: 'Simpan kode QR',
        address: 'Alamat setoran',
        copy: 'alamat salinan',
        num: 'Jumlah setoran',
        Upload: 'Unggah tangkapan layar detail pembayaran',
        Submit: 'Konfirmasi',
        tip: 'Masukkan jumlahnya',
        tip1: 'Unggah tangkapan layar',
        copySuc: 'berhasil menyalin'
    },
    newChange: {
        num: 'Masukkan jumlahnya',
        psd: 'silakan masukkan kata sandi',
        hq: 'kutipan',
        bb: 'Pusat Bantuan',
        sec: 'Diverifikasi',
        ass: 'aktiva',
        pool: 'manajemen keuangan',
        newc: 'tentang kami',
        rechange: 'mengisi ulang',
        with: 'pelayanan pelanggan',
        a1: 'Keuangan',
        a2: 'Menemukan dalam tahanan (USDT)',
        a3: 'Keuntungan Hari Ini',
        a4: 'Penghasilan kumulatif',
        a5: 'Dalam proses',
        a6: 'Pesanan',
        a7: 'Aturan',
        a8: 'Pengembalian harian',
        a9: 'Batas tunggal',
        a10: 'Siklus',
        a11: 'Pecently(Pendapatan Harian)',
        a12: 'Batas tunggal',
        a13: 'Jumlah tahanan：',
        a14: 'Berlangganan',
        a15: 'Penghitungan penghasilan',
        a16: 'Bata dipindahkan dengan menghosting USDT ke platform, dan platform adalah tim profesional yang melakukan arbitrasi untuk memindahkan batu bata...',
        a17: 'Satu anggota menyetor 10.000U pada platform, dan memilih produk manajemen kekayaan dengan jangka waktu 5 hari dan tingkat pengembalian harian 0,3% hingga 0,5%.Tertinggi harian:10000U x 0,5% = 50 U n Itu adalah pendapatan 150U-250U dapat diperoleh setelah 5 hari, pendapatan dikeluarkan setiap hari, dan pendapatan yang dikeluarkan dapat diakses kapan saja. Pokok yang disetor habis. Setelah itu, akan menjadi ...',
        a18: 'Tentang ganti rugi',
        a19: 'Jika Anda ingin mentransfer pokok yang belum kedaluwarsa, ganti rugi yang dilikuidasi akan dikenakan. Ganti rugi yang dilikuidasi = rasio penyelesaian default * sisa hari * jumlah investasi. Contoh: produk adalah default settlement ratio adalah 0.4%, Karena dalam 3 hari tersisa, jumlah investasi adalah 1000, maka ganti rugi = 0.4%*3...',
        a20: 'Saya ingin bergabung',
        help: 'Pusat Bantuan',
    },
    msgbank: {
        msgTitle: 'Pesan',
        noticeTitle: 'Announcement',
        msgContent: 'Anda memiliki pesan yang belum dibaca',
        msgBtn: 'Pergi periksa',
        bankCard: 'Kartu Bank',
        inputTips: 'Silakan masukkan informasi lengkap',
        submitSuccess: 'Submitted successfully',
        CardUserName: 'Account holder name',
        BankName: 'Opening Bank',
        OpenBank: 'Branch Membuka Akun',
        CardNo: 'Nomor kartu bank',
        noBindBankmsg: 'Anda belum menghubungkan kartu bank Anda belum',
        noBindBankBtn: 'Unbind',
        modify: 'ubah',
    }
}