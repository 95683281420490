<template>
  <div id="app" v-if="show">
    <transition :name="SkipSwitchName">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import userApi from '@/api/user'
// import { Token } from '@/utils/mm'
import { EventBus } from '@/utils/event-bus'
import { getSignalR } from '@/utils/signal'
import { mapGetters } from 'vuex'
import { returnLang, showId } from '@/utils/validate'

export default {
  name: 'App',

  data() {
    return {
      SkipSwitchName: '',
      show: false
    }
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.meta.tx > from.meta.tx) {
  //       this.SkipSwitchName = 'Skright'
  //     } else {
  //       this.SkipSwitchName = 'Skleft'
  //     }
  //   }
  // },
  created() {
    // if (Token.get()) {
    // this.getUserInfo()
    // } else {
    //   this.getbasic()
    // }
    // const fullPath = localStorage.getItem('fullPath')
    // if (fullPath) {
    //   this.$router.push(fullPath)
    // }
    // if (window.frames.length === parent.frames.length) {
    //   if (IsPC()) {
    //     location.href = location.origin + '/Home'
    //   }
    // }

    this.getbasic()
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getConnection()
    EventBus.$on('Login', () => {
      if (this.$store.getters.connection.state === 'Connected') {
        this.$store.getters.connection.stop()
      }
      this.getConnection()
    })
    EventBus.$on('Logout', () => {
      if (this.$store.getters.connection.state === 'Connected') {
        this.$store.getters.connection.stop()
      }
      this.getConnection()
    })
    // if (process.env.NODE_ENV === 'development') {
    //   new this.$vconsole()
    // }
  },
  methods: {
    returnLang,
    async getUserInfo() {
      try {
        const res = await userApi.userInfo()
        this.getbasic(res.DefaultLanguage)
      } catch (error) {
        this.getbasic()
      }
    },
    getConnection() {
      const connection = getSignalR()
      this.$store.commit('SET_CONNECTION', connection)
    },
    showCompanyName(lang, ICO) {
      let basicConfig = this.basicConfig
      let title = ''
      if (lang === 'zh') {
        title = basicConfig.CompanyName
      } else if (lang === 'en') {
        title = basicConfig.CompanyNameEnglish
      } else if (lang === 'ty') {
        title = basicConfig.CompanyNameThai
      } else if (lang === 'ft') {
        title = basicConfig.CompanyNameTraditional
      } else if (lang === 'jp') {
        title = basicConfig.CompanyNameJapanese
      } else if (lang === 'hy') {
        title = basicConfig.CompanyNameKorea
      } else if (lang === 'yn') {
        title = basicConfig.CompanyNameIndonesia
      }
      document.title = title
      var link = document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = ICO
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    async getbasic(lang = 'No') {
      const res1 = await userApi.getbasic()

      this.$store.commit('SET_BASIC', res1)
      let arr = []
      // res1.LanguageSelect.forEach((item) => {
      //   arr.push(this.returnLang(item, true))
      // })

      arr.push(this.returnLang('Chinese', true))
      arr.push(this.returnLang('English', true))
      arr.push(this.returnLang('Traditional', true))
      arr.push(this.returnLang('Thai', true))
      arr.push(this.returnLang('Korea', true))
      arr.push(this.returnLang('Japanese', true))
      arr.push(this.returnLang('Indonesia', true))
      arr.push(this.returnLang('German', true))
      arr.push(this.returnLang('French', true))
      arr.push(this.returnLang('Arabic', true))
      arr.push(this.returnLang('Russian', true))
      arr.push(this.returnLang('Spanish', true))
      let defaultLang = ''
      let localLang = ''
      // console.log('000' + showId(lang))
      if (lang === 'No') {
        defaultLang =
          localStorage.getItem('language') || showId(res1.DefaultLanguage)
      } else {
        defaultLang = showId(lang)
      }

      if (arr.indexOf(defaultLang) === -1) {
        // 如果默认语言不包含可用语言，则切换默认为默认语言的第一项
        localLang = arr[0]
      } else {
        localLang = defaultLang
      }
      // localLang = 'en' // 暂时固定为英文！！！！！！！
      this.showCompanyName(localLang, res1.ICO)
      // console.log('777' + localLang)
      this.$i18n.locale = localLang

      this.$store.commit('SET_LANG', localLang, false)
      localStorage.setItem('language', localLang)
      localStorage.setItem('IsHazard', JSON.stringify(res1.IsHazard))
      // console.log(res1.IsHazard)

      this.show = true
    }
  }
}
</script>
