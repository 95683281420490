import moment from 'moment'

// 手机验证
export function rightPhoneNumber(mobile) {
    return /^1\d{10}$/gi.test(mobile)
}
// 获取链接中的参数
export function getUrlParam(item) {
    var svalue = location.hash.match(
        new RegExp('[?&]' + item + '=([^&]*)(&?)', 'i')
    )
    return svalue ? svalue[1] : svalue
}

// 格式修改
export function splitData1(arr) {
    let categoryData = []

    arr.forEach((item) => {
        var obj = {
            time:
                (Date.parse(item.CreateTime.replace(/-/g, '/')) + 8 * 60 * 60 * 1000) /
                1000,
            // time: Date.parse(item.CreateTime.replace(/-/g, '/')) / 1000,
            open: item.Open,
            high: item.High,
            low: item.Low,
            close: item.Close
        }
        categoryData.push(obj)
    })
    return categoryData
}
export function IsreadSysNotice() {
    const flag = sessionStorage.getItem('props')
    if (!flag) {
        sessionStorage.setItem('props', true)
        return true
    } else {
        return false
    }
}
// export function IsreadSysNotice() {
//   let obj = JSON.parse(localStorage.getItem('SysNotice'))
//   const date = new Date()
//   let m =
//     date.getMonth() + 1 > 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
//   let d = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
//   let nowDate = `${m}-${d}`
//   if (obj) {
//     if (nowDate !== obj.data) {
//       localStorage.setItem(
//         'SysNotice',
//         JSON.stringify({
//           data: nowDate,
//           timer: 1
//         })
//       )
//       return true
//     }
//   } else {
//     localStorage.setItem(
//       'SysNotice',
//       JSON.stringify({
//         data: nowDate,
//         timer: 1
//       })
//     )
//     return true
//   }
// }
export function getLocalTime(value) {
    return moment(value)
        .local()
        .format('YYYY-MM-DD HH:mm:ss')
}
export function returnLang(lang, setLang = false) {
    let localeLang = ''

    switch (lang) {
        case 'Chinese':
            localeLang = 'zh'
            if (setLang) {
                this.$i18n.setLocaleMessage('zh', require('@/assets/lang/zh'))
            }
            break
        case 'Traditional':
            localeLang = 'ft'
            if (setLang) {
                this.$i18n.setLocaleMessage('ft', require('@/assets/lang/ft'))
            }
            break
        case 'Thai':
            localeLang = 'ty'
            if (setLang) {
                this.$i18n.setLocaleMessage('ty', require('@/assets/lang/ty'))
            }
            break
        case 'English':
            localeLang = 'en'
            if (setLang) {
                this.$i18n.setLocaleMessage('en', require('@/assets/lang/en'))
            }
            break
        case 'Korea':
            localeLang = 'hy'
            if (setLang) {
                this.$i18n.setLocaleMessage('hy', require('@/assets/lang/hy'))
            }
            break
        case 'Indonesia':
            localeLang = 'yn'
            if (setLang) {
                this.$i18n.setLocaleMessage('yn', require('@/assets/lang/yn'))
            }
            break

            //加着几个语言德语，法语，阿拉伯语，俄语，   西班牙语
        case 'German':
            localeLang = 'de'
            if (setLang) {
                this.$i18n.setLocaleMessage('de', require('@/assets/lang/de'))
            }
            break

        case 'French':
            localeLang = 'fr'
            if (setLang) {
                this.$i18n.setLocaleMessage('fr', require('@/assets/lang/fr'))
            }
            break

        case 'Arabic':
            localeLang = 'ar'
            if (setLang) {
                this.$i18n.setLocaleMessage('ar', require('@/assets/lang/ar'))
            }
            break

        case 'Russian':
            localeLang = 'ru'
            if (setLang) {
                this.$i18n.setLocaleMessage('ru', require('@/assets/lang/ru'))
            }
            break

        case 'Spanish':
            localeLang = 'es'
            if (setLang) {
                this.$i18n.setLocaleMessage('es', require('@/assets/lang/es'))
            }
            break

        default:
            localeLang = 'jp'
            if (setLang) {
                this.$i18n.setLocaleMessage('jp', require('@/assets/lang/jp'))
            }
    }
    return localeLang
}

// 余额变更类型
export const balanceChangeTypeArr = [{
            Id: 'Upper',
            Name: '存款'
        },
        {
            Id: 'Lower',
            Name: '取款'
        },
        {
            Id: 'Bet',
            Name: '投注'
        },
        {
            Id: 'Win',
            Name: '中奖'
        },
        {
            Id: 'BalanceToFinance',
            Name: '余额转收益宝'
        },
        {
            Id: 'FinanceeToBalance',
            Name: '收益宝转余额'
        },
        {
            Id: 'CashProfit',
            Name: '下级提现收益'
        }
    ]
    // 状态类型
export const TransferStatusArr = [{
        Id: 'Review',
        Name: '审核中'
    },
    {
        Id: 'Success',
        Name: '成功'
    },
    {
        Id: 'Fail',
        Name: '失败'
    }
]

const langArr = [{
        Id: 'zh',
        Name: 'Chinese'
    },
    {
        Id: 'ft',
        Name: 'Traditional'
    },
    {
        Id: 'ty',
        Name: 'Thai'
    },
    {
        Id: 'en',
        Name: 'English'
    },
    {
        Id: 'hy',
        Name: 'Korea'
    },
    {
        Id: 'yn',
        Name: 'Indonesia'
    },
    {
        Id: 'jp',
        Name: 'Japanese'
    },
    //加着几个语言德语，法语，阿拉伯语，俄语，   西班牙语
    {
        Id: 'de',
        Name: 'German'
    },
    {
        Id: 'fr',
        Name: 'French'
    },
    {
        Id: 'ar',
        Name: 'Arabic'
    },
    {
        Id: 'ru',
        Name: 'Russian'
    },
    {
        Id: 'es',
        Name: 'Spanish'
    },


]

/**
 * array show
 * @param {String} string
 */
export function showName(Id) {
    console.log(Id)
    if (Id === 'Error') {
        return '错误'
    } else {
        let name = ''
        langArr.some((item) => {
            if (item.Id === Id) {
                name = item.Name
                return true
            }
        })
        return name
    }
}
/**
 * array show
 * @param {String} string
 */
export function showId(Name) {
    if (Name === 'Error') {
        return '错误'
    } else {
        let Id = ''
        langArr.some((item) => {
            if (item.Name === Name) {
                Id = item.Id
                return true
            }
        })
        return Id
    }
}

export function IsPC() {
    var userAgentInfo = navigator.userAgent
    var Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
    ]
    var flag = true
    for (var i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) != -1) {
            flag = false
            break
        }
    }
    return flag
}